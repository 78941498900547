import { onMounted, onUpdated, ref, watch } from "vue";
import CalculatorTable from "../CalculatorTable/CalculatorTable.vue";
import { getTableHeaders, getTableRows } from "../../NewLoanCalculatorResult/mapTableData";


export default {
    name: "CalculatorCompareTable",
    components: {
        CalculatorTable
    },
    props: {
        tables: {
            type: Array,
            required: true
        },
        labels: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        const groupTables = ref([])

        const mapTableData = () => {
            // create a flat list of group with tables
            const array = []
            props.tables.forEach((grouptable, groupTableIndex) => {
                let compareIndex = 0
                
                grouptable.forEach((group) => {
                    group.tables.forEach((table, tableIndex) => {
                        array.push({
                            title: group.title,
                            showTitle: tableIndex === 0,
                            compareIndex: compareIndex,
                            table: {
                                headers: getTableHeaders(table),
                                tooltip: table.tooltip,
                                rows: getTableRows(table),
                                collapse: table.value ? true : false,
                                column: groupTableIndex
                            }
                        })
                        compareIndex++
                    })
                })
            })
            return array
        }

        const filterDataBasedOnCompareIndex = (array) => {
            // filter each group and add to compareable tables as an array to the same group
            array.forEach((groupTable) => {
                if (groupTables.value[groupTable.compareIndex] === undefined) {
                    groupTables.value[groupTable.compareIndex] = {compareIndex: groupTable.compareIndex, showTitle: groupTable.showTitle, titles: [groupTable.title], tables: [groupTable.table]}
                } else {
                    groupTables.value[groupTable.compareIndex].tables.push(groupTable.table)
                    groupTables.value[groupTable.compareIndex].titles.push(groupTable.title)
                }
            })
        }

        const matchTheTwoTables = (groupTable, table, tableIndex) => {
            const otherTable = groupTable.tables[tableIndex === 0 ? 1 : 0]
            if (otherTable) {

                table.rows.forEach((row, rowIndex) => { 
                    const rowName = row.row[0].name
                    const rowExist = otherTable?.rows?.find((otherRow) => otherRow.row[0].name === rowName)

                    if (!rowExist) {
                        otherTable?.rows?.splice(rowIndex, 0, {row: [{name: rowName}, {name: '-', styles: 'text-right'}]})
                    }
                })
            }
        }

        const tableDataAddMissingRows = () => {
            groupTables.value.forEach((groupTable) => {
                groupTable.tables.forEach((table, tableIndex) => {
                    matchTheTwoTables(groupTable, table, tableIndex)
                })
            })
        }

        const markGroupsWithOnlyOneTable = (group, classname) => {
            if (group.tables.length === 1) {
                switch (group.tables[0].column) {
                    case 0:
                        return classname + '--first'
                    case 1:
                        return classname + '--last'
                    default:
                        return ''
                }
            }
        }

        const setTables = () => {
            const array = mapTableData()
            filterDataBasedOnCompareIndex(array)
            tableDataAddMissingRows()
            groupTables.value.sort((a, b) => a.compareIndex - b.compareIndex)   
        }

        watch(() => props.tables, (newVal, oldVal) => {
            groupTables.value = []
            setTables()
        }, { deep: true })

        onMounted(() => {
            setTables()
        })


        return {
            groupTables,
            markGroupsWithOnlyOneTable
        }
    }
}