import _layoutComponentsProps from '../_layoutComponentsProps'
import CalculatorTable from '../../components/CalculatorTable/CalculatorTable.vue'
import CalculatorCompareTable from '../../components/CalculatorCompareTable/CalculatorCompareTable.vue'
import CalculatorSummary from '../../components/CalculatorSummary/CalculatorSummary.vue'
import CalculatorInfoBox from '../../components/CalculatorInfoBox/CalculatorInfoBox.vue'
import SendCalculation from '../../../../../../../Frontend/src/components/SendCalculation/SendCalculation.vue'
import { getTableHeaders, getTableRows } from '../mapTableData'

export default {
    name: 'NewLoanCalculatorResultMobile',
    components: {
        CalculatorTable,
        CalculatorCompareTable,
        CalculatorSummary,
        CalculatorInfoBox,
        SendCalculation,
    },
    props: _layoutComponentsProps,
    setup() {
        return {
            getTableHeaders,
            getTableRows,
        }
    },
}
