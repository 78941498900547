<script>
import options from './AdjustLoanContent.options'
export default options
</script>

<template>
    <article class="nyk-adjust-loan-content">
        <div></div>
        <div class="nyk-adjust-loan-content__inputs">
            <NewLoanCalculatorInputs
                :labels="labels"
                :housingTypes="housingTypes"
                :initData="initData"
                :displayErrors="displayErrors"
                :onChangeCallback="onChangeCallback"
                :showHousingTypes="showHousingTypes" />

            <div class="nyk-adjust-loan-content__inputs-wrapper">
                <fieldset class="nyk-adjust-loan-content__inputs-group" v-if="loanType?.hasMortgageLoan">
                    <legend class="nyk-adjust-loan-content__inputs-group-title">{{ labels.adjustLoanAdjustRealCreditLoanLabel }}</legend>

                    <div v-if="loanType?.hasMortInterestOnlyLoan" class="nyk-form-range">
                        <label for="mortInterestOnlyLoan" class="nyk-form-range__label">
                            {{ labels.interestOnlyPeriodLabel }}
                            <span>{{ mortgageLoanInterestOnlyLength }} {{ labels.yearUnitLabel }}</span>
                        </label>
                        <input
                            id="mortInterestOnlyLoan"
                            class="nyk-form-range__input"
                            v-model="mortgageLoanInterestOnlyLength"
                            type="range"
                            :min="loanType.mortgageLoanInterestOnlyLengthMinValue"
                            :max="loanType.mortgageLoanInterestOnlyLengthMaxValue" />
                    </div>

                    <div class="nyk-form-range">
                        <label for="mortgageLoanTenureLength" class="nyk-form-range__label">
                            {{ labels.durationLabel }} <span>{{ mortgageLoanTenureLength }} {{ labels.yearUnitLabel }}</span>
                        </label>
                        <input
                            id="mortgageLoanTenureLength"
                            class="nyk-form-range__input"
                            v-model="mortgageLoanTenureLength"
                            type="range"
                            :min="loanType.mortgageLoanTenureLengthMinValue"
                            :max="loanType.mortgageLoanTenureLengthMaxValue" />
                    </div>
                </fieldset>

                <fieldset class="nyk-adjust-loan-content__inputs-group" v-if="loanType?.hasBankLoan">
                    <legend class="nyk-adjust-loan-content__inputs-group-title">{{ labels.adjustLoanAdjustBankLoanLabel }}</legend>
                    <div class="nyk-form-range">
                        <label for="bankLoanTenureLength" class="nyk-form-range__label">
                            {{ labels.durationLabel }} <span>{{ bankLoanTenureLength }} {{ labels.yearUnitLabel }}</span>
                        </label>
                        <input
                            id="bankLoanTenureLength"
                            class="nyk-form-range__input"
                            v-model="bankLoanTenureLength"
                            type="range"
                            :min="loanType.bankLoanTenureLengthMinValue"
                            :max="loanType.bankLoanTenureLengthMaxValue" />
                    </div>
                </fieldset>
            </div>
        </div>
        <div class="nyk-adjust-loan-content__action">
            <button class="nyk-button nyk-button--filled-primary" v-on:click="submit">{{ labels.adjustLoanModalButtonLabel }}</button>
        </div>
    </article>
</template>
