<script>
import options from './NewLoanCalculatorResult.options'
export default options
</script>

<template>
    <article class="nyk-new-loan-calculator-result" :class="{ 'nyk-new-loan-calculator-result--compare': tables.length > 1 }">
        <NewLoanCalculatorResultDesktop
            :labels="labels"
            :infoBox="infoBox"
            :summaries="summaries"
            :tables="tables"
            :sendCalculation="sendCalculation"
            :showSendCalculation="showSendCalculation"
            :getLoanTypesForSummary="getLoanTypesForSummary"
            :switchLoanType="switchLoanType"
            :switchLoanTypeWithRedirect="switchLoanTypeWithRedirect"
            :openSendCalculationsModal="openSendCalculationsModal"
            :openCompareLoanModal="openCompareLoanModal"
            :adjustLoan="adjustLoan"
            :adjustLoanWithRedirect="adjustLoanWithRedirect"
            :quitCompareMode="quitCompareMode"
            :closeSendCalculationModal="closeSendCalculationModal" />

        <NewLoanCalculatorResultMobile
            :labels="labels"
            :infoBox="infoBox"
            :summaries="summaries"
            :tables="tables"
            :sendCalculation="sendCalculation"
            :showSendCalculation="showSendCalculation"
            :getLoanTypesForSummary="getLoanTypesForSummary"
            :switchLoanType="switchLoanType"
            :switchLoanTypeWithRedirect="switchLoanTypeWithRedirect"
            :openSendCalculationsModal="openSendCalculationsModal"
            :openCompareLoanModal="openCompareLoanModal"
            :adjustLoan="adjustLoan"
            :adjustLoanWithRedirect="adjustLoanWithRedirect"
            :quitCompareMode="quitCompareMode"
            :closeSendCalculationModal="closeSendCalculationModal" />
    </article>

    <div v-if="isLoading && !modalOpen" class="nyk-loader">
        <div class="nyk-loader__content">
            <div class="nyk-loader__content__circle"></div>
        </div>
    </div>

    <Modal />
</template>
