<script>
import options from "./ChatMessage.options"
export default options
</script>

<template>
    <div class="nyk-chat-message">
        <div class="nyk-chat-message__content" :class="messageClassName">
            <template v-if="message.type === 'statusMessage'">
                    <p class="nyk-chat-message__message-status" v-html="message.payload.statusMessage"></p>
            </template>
            <template v-if="message.type === 'html'">
                    <div class="nyk-chat-message__message-html" v-html="decodeUnicode(message.payload.html)"></div>
            </template>
            <template v-else-if="message.type === 'text'">
                    <div class="nyk-chat-message__message-html">
                        <p>{{ message.payload.text }}</p>
                    </div>
            </template>
            <template v-if="message.type === 'links'">
                <template v-for="link in message.payload.links">
                    <template v-if="link.type === 'action_link'">
                            <button @click="sendActionLink(link)" :disabled="chatExpired" class="nyk-chat-message__action-link nyk-button nyk-button--outline-primary">
                                    {{ link.text }}
                            </button>
                    </template>
                    <template v-else-if="link.type === 'external_link'">
                        <a class="nyk-chat-message__external-link nyk-button nyk-button--outline-primary nyk-button--icon" :href="link.url" :target="link.linkTarget">
                            {{ link.text }}
                            <div class="nyk-button__icon">
                                <svg role="presentation" viewBox="0 0 16 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.5 0.5L7.5 8.5" stroke="#07094A" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path d="M8.5 0.5H15.5V7.5" stroke="#07094A" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path
                                        d="M12.5 9.5V14C12.5 14.3978 12.342 14.7794 12.0607 15.0607C11.7794 15.342 11.3978 15.5 11 15.5H2C1.60218 15.5 1.22064 15.342 0.93934 15.0607C0.658035 14.7794 0.5 14.3978 0.5 14V5C0.5 4.60218 0.658035 4.22064 0.93934 3.93934C1.22064 3.65804 1.60218 3.5 2 3.5H6.5"
                                        stroke="#07094A" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </a>
                    </template>
                </template>
            </template>
        </div>
    </div>
</template>