import { ref, watch, nextTick } from "vue"
import SignupStepEmail from "../SignupStepEmail/SignupStepEmail.vue"
import emailValidator from "../../../../../Frontend/src/foundation/js/inputValidators/emailValidator"
import notEmptyInput from "../../../../../Frontend/src/foundation/js/inputValidators/notEmptyInput"
import SignupStepSelectNewsletters from "../SignupStepSelectNewsletters/SignupStepSelectNewsletters.vue"
import SignupStepFullname from "../SignupStepFullname/SignupStepFullname.vue"
import SignupStepReceipt from "../SignupStepReceipt/SignupStepReceipt.vue"
import newslettersSignupSubmit from "../../../../../Frontend/src/foundation/js/signupFlows/newslettersSignupSubmit"
import getGeneralComputedValues from "../../../../../Frontend/src/foundation/js/signupFlows/getGeneralComputedValues"
import handleValidateInputAndProceed from "../../../../../Frontend/src/foundation/js/signupFlows/handleValidateInputAndProceed"
import { stepComponentNames } from "../newsletterSignup.common";

export default {
  name: "SignupMultipleNewsletters",
  components: {
    SignupStepSelectNewsletters,
    SignupStepEmail,
    SignupStepFullname,
    SignupStepReceipt,
  },
  props: {
    labels: {
      type: Object,
      required: true,
    },
    apiUrl: {
      type: String,
      required: true,
    },
    newsletters: {
      type: Array,
      required: true,
    },
    newslettersUserInputs: {
      type: Array,
      required: true,
    },
    enableUserTrack: {
        type: Boolean,
        default: false,
        required: false
    }
  },
  setup(props) {
    // this object contains the entire set of inputs available for the complete form.

    // this object contains stepinformation.
    // all steps are described here
    const stepsConfigAndOrder = ref([
      {
        step: 1, //order - manual number. The order of the array determins the steporder
        name: "SignupStepSelectNewsletters", // stepname. Must correspond to a component declared under "components"
        completed: false, // are all fields valid?
        texts: {
          title: props.labels.title,
          summary: props.labels.summary,
          next: props.labels.nextButtonText
        }, // configuration of texts
      },
      {
        step: 2, //order - manual number. The order of the array determins the steporder
        name:  stepComponentNames.SignupStepEmail, // stepname. Must correspond to a component declared under "components"
        completed: false, // are all fields valid?
        texts: {
          title: props.labels.title,
          summary: props.labels.summary,
          emailLabel: props.labels.emailLabel,
          emailPlaceholder: props.labels.emailPlaceholder,
          emailErrorMessage: props.labels.emailErrorMessage,
          next: props.labels.nextButtonText
        }, // configuration of texts
      },
      {
        step: 3,
        name: stepComponentNames.SignupStepFullname,
        completed: false,
        texts: {
          title: props.labels.title,
          firstNameLabel: props.labels.firstNameLabel,
          firstNamePlaceholder: props.labels.firstNamePlaceholder,
          firstNameErrorMessage: props.labels.firstNameErrorMessage,
          lastNameLabel: props.labels.lastNameLabel,
          lastNamePlaceholder: props.labels.lastNamePlaceholder,
          lastNameErrorMessage:  props.labels.lastNameErrorMessage,
          finalize: props.labels.finalizeButtonText
        },
      },
      {
        step: 4,
        name: stepComponentNames.SignupStepReceipt,
        completed: false,
        texts: {
          title: props.labels.title,
          summary: props.labels.signupStepReceipt,
          finishSignupLabel: props.labels.finishSignupLabel,
        },
      },
    ])

    const userInput = ref([
      ...props.newslettersUserInputs,
      {
        field: "email",
        value: "", // you can prefill the inputfield by adding a value here.
        step: stepComponentNames.SignupStepEmail,
        validator: emailValidator,
        isValid: false,
      },
      {
        field: "firstname",
        value: "",
        step: stepComponentNames.SignupStepFullname,
        validator: notEmptyInput,
        isValid: false,
      },
      {
        field: "lastname",
        value: "",
        step: stepComponentNames.SignupStepReceipt,
        validator: notEmptyInput,
        isValid: false,
      },
    ])

    const {
      getNamedFieldInputData,
      resetSignupForm,
      currentStepName,
      currentStepTexts,
      hasPreviousStep,
      stepCounterText,
      stepCounterAccessibleText,
      showFieldErrorMessage,
      returnToPreviousStep,
    } = getGeneralComputedValues(userInput.value, stepsConfigAndOrder.value, props.labels)

    const inputCallback = (inputData, fieldName) => {
      const inputDataFieldIndex = userInput.value.findIndex(
        (field) => field.field === fieldName
      );
      const inputDataField = userInput.value[inputDataFieldIndex];
      inputDataField.isValid = inputDataField.validator(inputData);
      inputDataField.value = inputData;
    };

    const currentStepComponentFocusRef = ref(null);
    const stepAnnouncerRef = ref(null);
    const displayInputError = ref(false)

    const validateInputAndProceed = function (inputData) {
      const validSuccess = handleValidateInputAndProceed(
        inputData,
        userInput.value,
        stepsConfigAndOrder.value,
        currentStepName.value,
        submitData
      )

      displayInputError.value = validSuccess
    }

    const submitData = async function (inputData) {
      const newsletters = []

      props.newsletters.filter((newsletter) => {
        const newsletterInput = inputData.find((field) => field.field === newsletter.name)
        if (newsletterInput.value === true) {
          newsletters.push(newsletter.name)
        }
      })

      const body = {
        email: inputData.find((field) => field.field === "email").value,
        firstName: inputData.find((field) => field.field === "firstname").value,
        lastName: inputData.find((field) => field.field === "lastname").value,
        newsletters: newsletters,
        source: window.location.href,
        enableUserTrack: props.enableUserTrack === "true"
      }

      await newslettersSignupSubmit(body, props.apiUrl)
    }

    
    const announceStep = async () => {
      stepAnnouncerRef.value.textContent = stepCounterAccessibleText.value;
    };

    const setFocusElement = async (stepName) => {
      await nextTick();
      if (stepName === stepComponentNames.SignupStepEmail) {
        currentStepComponentFocusRef.value.emailInputElement.focus();
      } else if (stepName === stepComponentNames.SignupStepFullname) {
        currentStepComponentFocusRef.value.firstnameInputRef.focus();
      } else if (stepName === stepComponentNames.SignupStepReceipt) {
        currentStepComponentFocusRef.value.finishSignupButtonRef.focus();
      }
      return true;
    };

    watch(
      () => currentStepName.value,
      () => {
        announceStep();
        setFocusElement(currentStepName.value);
      }
    );

    return {
      inputCallback,
      currentStepTexts,
      currentStepComponentFocusRef,
      displayInputError,
      currentStepName,
      getNamedFieldInputData,
      validateInputAndProceed,
      stepCounterText,
      stepAnnouncerRef,
      hasPreviousStep,
      returnToPreviousStep,
      showFieldErrorMessage,
      resetSignupForm,
    }
  },
}
