export default {
    labels: {
        type: Object,
        required: true,
    },
    infoBox: {
        type: Array,
        required: true,
    },
    summaries: {
        type: Array,
        required: true,
    },
    tables: {
        type: Array,
        required: true,
    },
    sendCalculation: {
        type: Object,
        required: true,
    },
    showSendCalculation: {
        type: Boolean,
        required: true,
    },
    getLoanTypesForSummary: {
        type: Function,
        required: true,
    },
    switchLoanType: {
        type: Function,
        required: true,
    },
    switchLoanTypeWithRedirect: {
        type: Function,
        required: true,
    },
    openSendCalculationsModal: {
        type: Function,
        required: true,
    },
    openCompareLoanModal: {
        type: Function,
        required: true,
    },
    adjustLoan: {
        type: Function,
        required: true,
    },
    adjustLoanWithRedirect: {
        type: Function,
        required: true,
    },
    quitCompareMode: {
        type: Function,
        required: true,
    },
    closeSendCalculationModal: {
        type: Function,
        required: true,
    },
}
