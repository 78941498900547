import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'CalculatorTable',
    props: {
        labels: {
            type: Object,
            required: true,
        },
        headers: {
            type: Array,
            required: false,
            default: [],
        },
        rows: {
            type: Array,
            required: false,
            default: [],
        },
        tooltip: {
            type: String,
            required: false,
        },
        collapse: {
            type: Boolean,
            required: false,
            default: false,
        },
        title: {
            type: String,
            required: false,
        }
    },
    setup(props) {
        const store = useStore()
        const tableContainerRef = ref(null)
        const tableCollapsed = ref(false)
        const hiddensTableItemsShown = ref(false)
        const uniqueTableId = ref(Math.random().toString(36).substr(2, 9))

        const hiddenTableItems = computed(() => {
            if (props.rows.find((row) => row.hide)) {
                return true
            }

            return false
        })

        const toggleTable = function () {
            tableCollapsed.value = !tableCollapsed.value
        }

        const toggleHiddenRows = function () {
            hiddensTableItemsShown.value = !hiddensTableItemsShown.value
        }

        const showTooltip = function (title) {
            store.dispatch('modal/setModalContent', {
                modalTitle: title,
                style: ['small', 'white'],
                htmlContent: props.tooltip,
            })
            store.dispatch('modal/setOpen', { open: true })
        }

        const tableStylesHiddenRow = computed(() => hiddensTableItemsShown.value ? ' nyk-calculator-table--show-hidden-row' : '')
        const getTableItemStyles = (item) => item.styles ? 'nyk-table__container__table__body__row__item--' + item.styles : ''
        const getAccordionNameFromTable = () => props.rows[0]?.row[0]?.name
        const getAccordionValueFromTable = () => props.rows[0]?.row[1]?.name

        return {
            tableContainerRef,
            tableCollapsed,
            hiddenTableItems,
            hiddensTableItemsShown,
            toggleTable,
            toggleHiddenRows,
            showTooltip,
            tableStylesHiddenRow,
            getTableItemStyles,
            getAccordionNameFromTable,
            getAccordionValueFromTable,
            uniqueTableId
        }
    },
}
