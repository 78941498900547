import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useStore } from "vuex";
import ChatMessage from "./ChatMessage/ChatMessage";
import ChatWindow from "./ChatWindow/ChatWindow";
import debounce from "foundation/js/debounce";
import startTypingAnimation from "foundation/js/typingAnimation";

export default {
  name: "ChatPanel",
  components: {
    ChatWindow,
    ChatMessage,
  },
  props: {
  },
  setup() {
    const store = useStore();
    const message = ref(undefined);
    const buttonTextRef0 = ref(undefined);
    const buttonTextRef1 = ref(undefined);
    const introChatButtonRef = ref(undefined);
    const isSendingMessage = ref(false)
    const windowScrolled = ref(false)
    const permanentlyCollapseStartChatButton = ref(false)
    const elementContainerRef = ref(undefined);

    function getLabel(label) {
      const returnLabel = store.state.chatPanel.labels[label];
      if (!returnLabel) console.error("Label does not exist", label);
      return returnLabel ? returnLabel : `[${label}]`;
    }

    function openChatWindow() {
      store.commit("chatPanel/set_chat_window_open", true);
    }

    const maxCharacters = computed(() => {
      return store.state.chatPanel.conversation?.maxInputChars ?? 110;
    });
    const chatWindowOpen = computed(() => {
      return store.state.chatPanel.chatWindowOpen;
    });

    const messages = computed(() => {
      const messages = store.state.chatPanel.messages;
      return messages ? messages : [];
    });

    const isAwaitingResponse = computed(() => {
      return store.state.chatPanel.isAwaitingResponse;
    });

    async function sendMessage() {
      if (sendMessageDisabled.value) return false;
      try {
        const newMessage = message.value;
        message.value = "";
        isSendingMessage.value = true
        await store.dispatch("chatPanel/sendMessage", newMessage);
      } finally  {
        isSendingMessage.value = false
      }
    }
    function messageAuthorIsUser(message) {
      if (!message || !message.source) return false;
      return message.source === "client";
    }


    function getMessageAuthorLabel(message) {
      if (!message || !message.source) return "";
      return message.source === "bot" && message.isHumanAgent ? store.state.chatPanel.labels["authorLabelCustomerService"] :
      message.source === "bot"
        ? store.state.chatPanel.labels["authorLabelCustomerBot"]
        : "";
    }

    function feedbackButtonIsActive(buttonValue, id) {
      const messageFeedback = store.state.chatPanel.messageFeedback.find(
        (message) => id === message.id
      );
      if (messageFeedback === undefined) return "";
      return messageFeedback.value === buttonValue ? true : false;
    }

    const isLoadingConversation = computed(() => {
      return store.state.chatPanel.isAwaitingResponse;
    });

    const menuOverlayShown = computed(() => {
      return store.state.menu.searchOverlayShown
        || store.state.menu.submenuOpen
        || store.state.menu.otherSitesDropdownShown
        || store.state.menu.languageDropdownShown
        || store.state.menu.topLevelPagesDropdownShown;
    })

    function handleKeyDown(event) {
      if (event.key === "Enter") {
        sendMessage();
      }
    }

    const inputDisabled = computed(() => {
      return store.state.chatPanel.connectionError || store.getters["chatPanel/chatExpired"]
    })

    const sendMessageDisabled = computed(() => {
      return (
        isLoadingConversation.value ||
        !message.value ||
        message.value.length === 0
      );
    });

    function sendMessageFeedback(value, id) {
      store.dispatch("chatPanel/sendMessageFeedback", {
        id: id,
        feedback: value,
      });
    }

    function setWindowScrolled() {
      windowScrolled.value = window.scrollY > 0;
    }

    const showCollapsedChatButton = computed(() => {
      if (permanentlyCollapseStartChatButton.value) return true;
      return windowScrolled.value
    })

    function collapseStartChatButton() {
      permanentlyCollapseStartChatButton.value = true
    }

    function handleFeedback(type, id) {
      let feedbackValue;
      if (type === 'positive') {
        feedbackValue = feedbackButtonIsActive('positive', id) ? 'remove-positive' : 'positive';
      } else if (type === 'negative') {
        feedbackValue = feedbackButtonIsActive('negative', id) ? 'remove-negative' : 'negative';
      }
      sendMessageFeedback(feedbackValue, id);
    }

    const chatExpired = computed(() => {
      return store.state.chatPanel.conversation?.state?.expired;
    })

    function showFeedbackButtons(message) {
      if (chatExpired || !message || !message.source) return false;
      return message.source !== "client" && message.source !== "system"
    }

    function addScrollEventListener() {
      window.addEventListener('scroll', debounce(() => {
        setWindowScrolled();
      }, 10));
      setWindowScrolled();
    }

    function removeScrollEventListener() {
      window.removeEventListener('scroll', debounce(() => {
        setWindowScrolled();
      }, 10));
    }

    watch(menuOverlayShown, (newVal) => {
      elementContainerRef.value.style.display = newVal ? "none" : "block"
    })

    onMounted(() => {
      elementContainerRef.value.style.display = "block";
      addScrollEventListener();
      addConversationIdBroadcastChannel();
      startAnimation();
    });

    onUnmounted(() => {
      removeScrollEventListener();
    })

    function addConversationIdBroadcastChannel() {
      const bc = new BroadcastChannel("nyk-chat-conversation-id");
      bc.onmessage = (e) => {
        if (e.data === "loadChatConversionSessionId") {
          // Prepare session data to share
          const chatPanelConversation = sessionStorage.getItem("chatPanelConversation");

          // Only broadcast if sessionData is not empty
          if (chatPanelConversation) {
            bc.postMessage({ chatPanelConversation });
          }
        } else if (e.data === "deleteChatConversation") {
          // Chat session has been deleted in another tab
          sessionStorage.removeItem("chatPanelConversation");
          store.dispatch("chatPanel/resetChat")
        } else if (e.data.chatPanelConversation) {
          const newSessionStorageObject = JSON.parse(e.data.chatPanelConversation);
          const mergedSessionStorageObject = mergeSessionStorageObject(newSessionStorageObject);
          const sessionData = JSON.stringify(mergedSessionStorageObject);

          // Update sessionStorage with received session data
          sessionStorage.setItem(
            "chatPanelConversation",
            sessionData
          );
          // If the chat window was open before or if it has never been close, open it
          if (mergedSessionStorageObject.windowOpen) {
            openChatWindow();
          }
        }
      };

      // Request session data from other tabs
      bc.postMessage("loadChatConversionSessionId");
    }

    function mergeSessionStorageObject(newSessionStorageObject) {
      const currentSession = sessionStorage.getItem("chatPanelConversation");
      const currentSessionStorageObject = currentSession ? JSON.parse(currentSession) : {};
      // Keep current state of windowOpen
      newSessionStorageObject.windowOpen = currentSessionStorageObject.windowOpen ?? false;
      return newSessionStorageObject;
    }

    function startAnimation() {
      const chatButtonText1 = getLabel("startChatButtonText1");
      const chatButtonText2 = getLabel("startChatButtonText2");
      watch(
        buttonTextRef0,
        (newVal) => {
          if (newVal) {
            startTypingAnimation(
              [buttonTextRef0, buttonTextRef1],
              [chatButtonText1, chatButtonText2]
            );
          }
        },
        { immediate: true }
      );
    }

    return {
      elementContainerRef,
      inputDisabled,
      showCollapsedChatButton,
      collapseStartChatButton,
      feedbackButtonIsActive,
      buttonTextRef0,
      buttonTextRef1,
      handleFeedback,
      chatWindowOpen,
      isAwaitingResponse,
      showFeedbackButtons,
      messageAuthorIsUser,
      maxCharacters,
      openChatWindow,
      sendMessage,
      isSendingMessage,
      getMessageAuthorLabel,
      handleKeyDown,
      introChatButtonRef,
      isLoadingConversation,
      sendMessageDisabled,
      message,
      messages,
    };
  },
};
