import { nextTick, onMounted, ref, watch, computed, onUnmounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import ChatMenu from "../ChatMenu/ChatMenu"
import ChatFeedback from "../ChatFeedback/ChatFeedback"
import ChatConnectionError from "../ChatConnectionError/ChatConnectionError"
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'foundation/js/utils/bodyScrollLock';
export default {
  name: "ChatWindow",
  components: {
    ChatMenu,
    ChatFeedback,
    ChatConnectionError
  },
  props: {
    initialMessage: {
      type: String,
      default: "",
    }
  },
  setup() {
    const store = useStore();
    const menuIsOpen = ref(false);
    const messageContainer = ref(undefined)
    const windowWidth = ref(undefined)
    const windowHeight = ref(undefined)
    const chatWindowContent = ref(undefined)
    function getLabel(label) {
      const returnLabel = store.state.chatPanel.labels[label];
      if (!returnLabel) console.error("Label does not exist", label);
      return returnLabel ? returnLabel : `[${label}]`;
    }

    function endConversation() {
      menuIsOpen.value = false
      if (store.getters["chatPanel/chatInProgress"] && !store.state.chatPanel.showFeedback) {
        store.dispatch("chatPanel/showFeedback", true);
      }
      else if (store.getters["chatPanel/chatInProgress"]) {
        store.dispatch("chatPanel/resetChat");
      }
      else {
        store.commit("chatPanel/reset_chat_client_state");
      }
    }

    function minimizeChatWindow() {
      store.commit("chatPanel/set_chat_window_open", false);
    }

    function openMenu() {
      menuIsOpen.value = true;
    }

    function closeMenu() {
      menuIsOpen.value = false;
    }

    function navigateBack() {
      if (menuIsOpen.value) {
        menuIsOpen.value = false;
      }
      else if (store.state.chatPanel.showFeedback) {
        store.dispatch("chatPanel/showFeedback", false);
      }
    }

    const showFeedback = computed(() => {
      return store.state.chatPanel.showFeedback;
    })

    const showConnectionError = computed(() => {
      return store.state.chatPanel.connectionError && !menuIsOpen.value
    })

    async function scrollToLastMessage() {
      await nextTick();
      if (messageContainer.value) {
        const lastMessage = messageContainer.value.lastElementChild;
        if (!lastMessage) return;
        chatWindowContent.value.scrollTo({
          top: lastMessage.offsetTop,
          behavior: 'smooth'
        });
      }
    }

    const isAwaitingResponse = computed(() => {
      return store.state.chatPanel.isAwaitingResponse;
    });
    
    const chatIsShown = computed(() => {
      return !menuIsOpen.value && !showFeedback.value &&  !showConnectionError.value
    })

    watch(() => [store.state.chatPanel.messages, isAwaitingResponse.value, chatIsShown.value], () => {
      if (chatIsShown.value) {
        scrollToLastMessage()
      }
    });

    function toggleBodyScrollLock() {
      const chatWindowOpen = store.state.chatPanel.chatWindowOpen;
      if (chatWindowOpen && (windowWidth.value < 1240 && windowHeight.value < 680) || (windowWidth.value < 905)) {
        disableBodyScroll(chatWindowContent.value)
      }
      else {
        enableBodyScroll(chatWindowContent.value)
      }
    }

    watch(() => [windowWidth.value, windowHeight.value], () => {
      toggleBodyScrollLock()
    });

    watch(() => store.state.chatPanel.chatWindowOpen, () => {
        toggleBodyScrollLock()
    });

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
      windowHeight.value = window.innerHeight;
    };

    function addHandleResizeEventListener() {
      window.addEventListener('resize', handleResize);
      handleResize();
    }


    function resumeOrStartConversation() {
      const sessionStorageChatPanelConversation = sessionStorage.getItem('chatPanelConversation');
      if (!sessionStorageChatPanelConversation) {
        store.dispatch("chatPanel/startConversation");
        return true
      }
      else if (!store.state.chatPanel.conversation?.id) {
        store.dispatch("chatPanel/resumeConversation")
      }
    }

    const chatWindowActive = computed(() => {
      return !showFeedback.value && !showConnectionError.value && !menuIsOpen.value
    })

    const showMenuButton = computed(() => {
      return !menuIsOpen.value && !showFeedback.value
    })

    const showBackButton = computed(() => {
      const feedbackStep = store.state.chatPanel.feedbackStep
      if (feedbackStep === 'ChatFeedbackPhoneNumber' || feedbackStep === 'ChatFeedbackReview') {
        return false
      }
      return true
    })

    onMounted(() => {
      addHandleResizeEventListener()
      resumeOrStartConversation()
      scrollToLastMessage()
    });

    onBeforeUnmount(() => {
      enableBodyScroll(chatWindowContent.value)
    })

    return {
      chatIsShown,
      chatWindowActive,
      chatWindowContent,
      showMenuButton,
      showBackButton,
      openMenu,
      showConnectionError,
      navigateBack,
      closeMenu,
      getLabel,
      showFeedback,
      messageContainer,
      menuIsOpen,
      endConversation,
      minimizeChatWindow,
    };
  },
};
