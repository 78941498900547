import {createApp} from 'vue'
import CalculatorCompareTable from './CalculatorCompareTable'

const el = '.nyk-component--calculator-compare-table'

export default function(Store){
    const DOM = document.querySelectorAll(el)
    if(DOM.length){
        DOM.forEach((CalculatorCompareTableDOM)=>{
            const component = createApp(CalculatorCompareTable).use(Store)
            component.mount(CalculatorCompareTableDOM)
        })
    }
}