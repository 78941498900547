import { computed, ref } from "vue"
import emailValidator from "../../foundation/js/inputValidators/emailValidator"
import notEmptyInput from "../../foundation/js/inputValidators/notEmptyInput"
import phoneValidator from "../../foundation/js/inputValidators/phoneValidator"
import isBoolean from "../../foundation/js/inputValidators/isBoolean"
import cprValidator from "../../foundation/js/inputValidators/cprValidator"
import getContactedSubmit from "../../foundation/js/signupFlows/getContactedSubmit"
import getGeneralComputedValues from "../../foundation/js/signupFlows/getGeneralComputedValues"
import handleValidateInputAndProceed from "../../foundation/js/signupFlows/handleValidateInputAndProceed"

import SignupStepReceipt from "../../../../Features/Blocks/Newsletters/NewslettersSignup/SignupStepReceipt/SignupStepReceipt.vue"
import ContactStepIntro from "../ContactStepIntro/ContactStepIntro.vue"
import ContactStepContent from "../ContactStepContent/ContactStepContent.vue"
import ContactStepComments from "../ContactStepComments/ContactStepComments.vue"

export default {
  name: "ContactCard",
  components: {
    ContactStepIntro,
    ContactStepContent,
    ContactStepComments,
    SignupStepReceipt,
  },
  props: {
    labels: {
      type: Object,
      required: true,
    },
    formId: {
      type: String,
      required: true,
    },
    apiUrl: {
      type: String,
      required: true,
    },
    newsletterApiUrl: {
      type: String,
      required: true,
    },
    newsletters: {
      type: Array,
      required: false,
      default: () => [],
    },
    alignIntroImageRight: {
      type: Boolean,
      required: false,
      default: false,
    },
    imageSource: {
      type: String,
      required: true,
    },
    imageAltText: {
      type: String,
      required: false,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: null,
    },
    assignTo: {
        type: String,
        required: true,
      },
    enableUserTrack: {
        type: Boolean,
        default: false,
        required: false
    }
  },
  setup(props) {
    const bgColorClassName = computed(() => {
      return props.backgroundColor ? `nyk-contact-card--${props.backgroundColor}` : ""
    })

    const imagePosClassName = computed(() => {
      return props.alignIntroImageRight ? "nyk-contact-card--image-right" : ""
    })

    const contactCardClassNames = computed(() => {
      return `${bgColorClassName.value} ${imagePosClassName.value}`
    })

    const notRequiredInput = (input) => {
      return true
    }

    const onlyIfCustomer = (input) => {
      if (userInput.value.find((field) => field.field === "isCustomer").value) {
        return cprValidator(input)
      } else {
        return true
      }
    }

    // this object contains the entire set of inputs available for the complete form.
    const userInput = ref([
      {
        field: "firstname",
        value: "",
        validator: notEmptyInput,
        isValid: false,
      },
      {
        field: "lastname",
        value: "",
        validator: notEmptyInput,
        isValid: false,
      },
      {
        field: "email",
        value: "", // you can prefill the inputfield by adding a value here.
        validator: emailValidator,
        isValid: false,
      },
      {
        field: "phone",
        value: "", // you can prefill the inputfield by adding a value here.
        validator: phoneValidator,
        isValid: false,
      },
      {
        field: "cpr",
        value: "",
        validator: onlyIfCustomer, // should only be validated if the user is a customer
        isValid: false, // is valid as empty string if the user is not a customer
      },
      {
        field: "isCustomer",
        value: null,
        validator: isBoolean,
        isValid: false,
      },
      {
        field: "comment",
        value: "", // you can prefill the inputfield by adding a value here.
        validator: notRequiredInput,
        isValid: false,
      },
    ])

    // this object contains stepinformation.
    // all steps are described here
    let stepsConfigAndOrder = ref([
      {
        step: 0,
        name: "ContactStepIntro",
        completed: false,
        isCountableStep: false,
        texts: {
          title: props.labels.title,
          summary: props.labels.introSummary,
          next: props.labels.nextButtonText
        },
      },
      {
        step: 1,
        name: "ContactStepContent",
        completed: false,
        isCountableStep: true,
        texts: {
          title: props.labels.title,
          summary: props.labels.summary,
          next: props.labels.nextButtonText
        },
      },
      {
        step: 2,
        name: "ContactStepComments",
        completed: false,
        isCountableStep: true,
        texts: {
          title: props.labels.title,
          next: props.labels.nextButtonText
        },
      },
      {
        step: 3,
        name: "SignupStepReceipt",
        completed: false,
        isCountableStep: false,
        texts: {
          title: props.labels.receiptTitle,
          summary: props.labels.signupStepReceipt,
        },
      },
    ])

    const displayInputError = ref(false)

    const {
      getNamedFieldInputData,
      resetSignupForm,
      currentStepName,
      currentStepTexts,
      hasPreviousStep,
      stepCounterText,
      showFieldErrorMessage,
        returnToPreviousStep,
    } = getGeneralComputedValues(userInput.value, stepsConfigAndOrder.value, props.labels)

    const validateInputAndProceed = function (inputData) {
      const validSuccess = handleValidateInputAndProceed(
        inputData,
        userInput.value,
        stepsConfigAndOrder.value,
        currentStepName.value,
        submitData
      )

      displayInputError.value = validSuccess
    }

    const submitData = async function (inputData) {
      const body = {
        formIdentifier: props.formId,
        firstName: inputData.find((field) => field.field === "firstname").value,
        lastName: inputData.find((field) => field.field === "lastname").value,
        email: inputData.find((field) => field.field === "email").value,
        phone: inputData.find((field) => field.field === "phone").value,
        comment: inputData.find((field) => field.field === "comment").value,
        isCustomer: inputData.find((field) => field.field === "isCustomer").value,
        assignTo: props.assignTo,
        enableUserTrack: props.enableUserTrack === "true"
      }

      if (inputData.find((field) => field.field === "isCustomer").value) {
        body.cxr = inputData.find((field) => field.field === "cpr").value
      }

      const response = await getContactedSubmit(body, props.apiUrl)
    }

    return {
      currentStepTexts,
      displayInputError,
      currentStepName,
      getNamedFieldInputData,
      validateInputAndProceed,
      stepCounterText,
      returnToPreviousStep,
      showFieldErrorMessage,
      hasPreviousStep,
      resetSignupForm,
      contactCardClassNames,
    }
  },
}
