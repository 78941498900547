<script>
import options from "./ContactStepContent.options"
export default options
</script>

<template>
    <article class="nyk-signup-step nyk-contact-step-content">
        <div aria-live="polite" class="nyk-signup-step__content" :class="contentClassnames">
            <div class="nyk-signup-step__content__section--66-width nyk-signup-step__content__section--full-width-mobile">
                <div class="nyk-signup-step__header">
                    <h2 class="nyk-signup-step__title">{{ currentStepTexts.title }}</h2>
                    <p class="nyk-signup-step__stepcounter">Step {{ stepCounterText }}</p>
                </div>

                <div class="nyk-contact-step-content__is-customer">
                    <div class="nyk-contact-step-content__is-customer__content">
                        <p class="nyk-contact-step-content__is-customer__content__question">
                            {{ labels.alreadyCustomer }}
                        </p>
                        <div class="nyk-contact-step-content__is-customer__content__inputs">
                            <div class="nyk-signup-step__radio">
                                <input
                                    type="radio"
                                    name="isCustomer"
                                    id="isCustomer-yes"
                                    class="nyk-signup-step__radio__input"
                                    :checked="isCustomerInput === true"
                                    v-on:change="() => handleIsCustomerInput(true)" />
                                <span class="nyk-signup-step__radio__checkmark"></span>
                                <label class="nyk-signup-step__radio__label" for="isCustomer-yes">Ja</label>
                            </div>
                            <div class="nyk-signup-step__radio">
                                <input
                                    type="radio"
                                    name="isCustomer"
                                    id="isCustomer-no"
                                    class="nyk-signup-step__radio__input"
                                    :checked="isCustomerInput === false"
                                    v-on:change="() => handleIsCustomerInput(false)" />
                                <span class="nyk-signup-step__radio__checkmark"></span>
                                <label class="nyk-signup-step__radio__label" for="isCustomer-no">Nej</label>
                            </div>
                        </div>
                    </div>
                    <p class="nyk-signup-step__input-error-msg">
                        <template v-if="displayInputError && showFieldErrorMessage('isCustomer')">
                            {{ labels.alreadyCustomerErrorMessage ?? 'Besvar venligst om du er kunde i Nykredit' }}
                        </template>
                    </p>
                </div>
            </div>

            <div class="nyk-signup-step__content__section--33-width nyk-signup-step__summary" v-html="currentStepTexts.summary"></div>

            <div
                class="nyk-contact-step-onboarding-content__inputs nyk-signup-step__content__section--66-width nyk-signup-step__content__section--full-width-mobile">
                <div
                    class="nyk-contact-step-onboarding-content__inputs__input nyk-contact-step-onboarding-content__inputs__input--firstname">
                    <input
                        class="nyk-signup-step__input"
                        :placeholder="labels.firstName ?? 'Fornavn'"
                        v-model="firstNameInput"
                        v-on:keyup.enter="nextStepCallback([{ field: 'firstname', value: firstNameInput }])" />

                    <p class="nyk-signup-step__input-error-msg">
                        <template v-if="displayInputError && showFieldErrorMessage('firstname')">
                            {{ labels.firstnameErrorMessage ?? 'Indtast venligst et fornavn' }}
                        </template>
                    </p>
                </div>
                <div
                    class="nyk-contact-step-onboarding-content__inputs__input nyk-contact-step-onboarding-content__inputs__input--lastname">
                    <input
                        class="nyk-signup-step__input"
                        :placeholder="labels.lastName ?? 'Efternavn'"
                        v-model="lastNameInput"
                        v-on:keyup.enter="nextStepCallback([{ field: 'lastname', value: lastNameInput }])" />

                    <p class="nyk-signup-step__input-error-msg">
                        <template v-if="displayInputError && showFieldErrorMessage('lastname')">
                            {{ labels.lastnameErrorMessage ?? 'Indtast venligst et efternavn' }}
                        </template>
                    </p>
                </div>
                <div class="nyk-contact-step-onboarding-content__inputs__input nyk-contact-step-onboarding-content__inputs__input--phone">
                    <input
                        class="nyk-signup-step__input"
                        maxlength="11"
                        :placeholder="labels.phone ?? 'Telefon'"
                        v-model="phoneInput"
                        v-on:keyup.enter="nextStepCallback([{ field: 'phone', value: phoneInput }])" />

                    <p class="nyk-signup-step__input-error-msg">
                        <template v-if="displayInputError && showFieldErrorMessage('phone')">
                            {{ labels.phoneErrorMessage ?? 'Indtast venligst et telefonnummer' }}
                        </template>
                    </p>
                </div>
                <div class="nyk-contact-step-onboarding-content__inputs__input nyk-contact-step-onboarding-content__inputs__input--email">
                    <input
                        class="nyk-signup-step__input"
                        :placeholder="labels.email ?? 'E-mail'"
                        v-model="emailInput"
                        v-on:keyup.enter="nextStepCallback([{ field: 'email', value: emailInput }])" />

                    <p class="nyk-signup-step__input-error-msg">
                        <template v-if="displayInputError && showFieldErrorMessage('email')">
                            {{ labels.emailErrorMessage ?? 'Indtast venligst en email adresse' }}
                        </template>
                    </p>
                </div>
            </div>

            <template v-if="showCPR">
                <div class="nyk-signup-step__content__section--33-width nyk-signup-step__content__section--full-width-mobile">
                    <div class="nyk-contact-step-onboarding-content__inputs__input nyk-contact-step-onboarding-content__inputs__input--cpr">
                        <input
                            class="nyk-signup-step__input"
                            maxlength="11"
                            :placeholder="labels.cpr ?? 'CPR-nummer'"
                            v-model="cprInput"
                            v-on:input="cprFormatting"
                            v-on:keyup.enter="nextStepCallback([{ field: 'cpr', value: cprInput }])" />

                        <p class="nyk-signup-step__input-error-msg">
                            <template v-if="displayInputError && showFieldErrorMessage('cpr')">    
                                {{ labels.cprErrorMessage ?? 'Indtast venligst et cpr-nummer' }}
                            </template>
                        </p>
                    </div>
                </div>
            </template>

            <div class="nyk-signup-step__summary nyk-signup-step__summary--mobile" v-html="currentStepTexts.summary"></div>

            <div class="nyk-signup-step__mt-auto nyk-signup-step__content__section--full-width">
                <SignupStepActions
                    :previousStepCallback="previousStepCallback"
                    :nextStepCallback="
                        () =>
                            nextStepCallback([
                                { field: 'firstname', value: firstNameInput },
                                { field: 'lastname', value: lastNameInput },
                                { field: 'email', value: emailInput },
                                { field: 'phone', value: phoneInput },
                                { field: 'isCustomer', value: isCustomerInput },
                                { field: 'cpr', value: cprInput },
                            ])
                    "
                    :nextStepButtonText="currentStepTexts.next"
                    :stepCounterText="stepCounterText"
                    :hasPreviousStep="hasPreviousStep" />
            </div>
        </div>
    </article>
</template>
