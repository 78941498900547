/**
 * @param {string} formInputName - the component name of the form input Input
 * @param {Object} customConfig - custom config for form inputs
 * @param {Function} customConfigvalidatorFunction - custom validator function, will be used if there is no validator handed down from props
 */

import { ref, computed, defineEmits } from 'vue'

import formInputFormBinder from './formInputFormBinder'

const autoSelectFirstRadio = function (props) {
    return props.radioOptions ? Object.values(props.radioOptions)[0].value : null
}

export default function (formInputName, customConfig) {
    return {
        name: formInputName,
        props: {
            prefix: {
                type: String,
                required: false,
            },
            autocomplete: {
                type: String,
                default: 'off',
            },
            modelValue: {
                type: [String, Number, Boolean],
                required: false,
            },
            inputName: {
                type: String,
                required: true,
            },
            legend: {
                type: String,
                required: false,
            },
            isRequired: {
                type: Boolean,
                required: false,
            },
            label: {
                type: String,
                required: true,
            },
            placeholder: {
                type: String,
                required: false,
            },
            isValidationError: {
                // this props works via
                // forms will use this Input to notify the Input to show its possible errors
                type: Boolean,
                required: false,
                default: null,
            },
            isValid: {
                // this props works via
                // forms will use this Input to notify the Input to show validated input styles
                type: Boolean,
                required: false,
                default: null,
            },
            errorMessageLabel: {
                type: String,
                required: false,
            },
            helpMessageLabel: {
                type: String,
                required: false,
            },
            type: {
                type: String,
                required: false,
                default: 'text',
            },
            maxlength: {
                type: Number,
                required: false,
            },
            onChangeCallback: {
                type: Function,
                required: false,
                default: () => {},
            },
            formLink: {
                type: Function,
                required: false,
                default: null,
            },
            validatorFunction: {
                type: Function,
                required: false,
                default: null,
            },
            radioOptions: {
                type: Object,
                required: false,
            },
            checkboxes: {
                type: Array,
                required: false,
            },
            selectOptions: {
                type: Object,
                required: false,
            },
            disabled: {
                type: Boolean,
                required: false,
            },
            resetAriaLabel: {
                type: String,
                required: false,
                default: 'Reset',
            },
        },
        setup(props, { emit }) {
            const inputRef = ref(null)
            const localValue = ref(props.modelValue ?? autoSelectFirstRadio(props) ?? '')
            // generel validation functinality
            // Webshop handles Input validation in the form logic, and sends results via props.isValidationErro
            // Other forms like SFL forms uses the props.isValidationError to signal the Input to show any errors.
            const inputHasValidationErrors = computed(() =>
                // multiple ways of getting a validation of the input
                // 1 - external form validates the input = result is in props.isValidationError
                // 2 - recieved external validatorFunction via props = validate using that function
                // 3 - has internal function recived in commonConfig = validate use that function
                // If none of the above, just validation is cut short and passes with true
                props.isValidationError !== null
                    ? props.isValidationError
                    : props.validatorFunction
                    ? props.validatorFunction(localValue.value)
                    : typeof customConfig.validatorFunction === 'function'
                    ? customConfig.validatorFunction(localValue.value)
                    : true
            )

            const formLink = formInputFormBinder(props.formLink, inputHasValidationErrors, localValue, props.onChangeCallback)

            const resetValue = () => {
                emit('update:modelValue', '')
                setTimeout(() => inputRef.value.focus(), 120)
            }

            return {
                formLink,
                inputHasValidationErrors,
                localValue,
                inputRef,
                resetValue,
            }
        },
    }
}
