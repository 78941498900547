import { ref } from 'vue'
import NewLoanCalculatorInputs from '../components/NewLoanCalculatorInputs/NewLoanCalculatorInputs.vue'
import mapNewLoanUrlParams from '../js/mapNewLoanUrlParams'
import Modal from '../components/Modal/Modal.vue'

export default {
    name: 'NewLoanCalculatorInput',
    components: {
        NewLoanCalculatorInputs,
        Modal,
    },
    props: {
        labels: {
            type: Object,
            required: true,
        },
        housingTypes: {
            type: Array,
            required: true,
        },
        initData: {
            type: Object,
            required: false,
        },
        juraModal: {
            type: Object,
            required: true,
        },
        systemErrorMessage: {
            type: String,
            required: false,
        },
    },
    setup(props) {
        const housePrice = ref('')
        const ownPayment = ref('')
        const housingType = ref('')
        const defaultBankLoanTenureLength = ref(props.initData.mortgageLoanInterestOnlyLength ?? 0)
        const defaultMortgageLoanTenureLength = ref(props.initData.mortgageLoanTenureLength ?? 0)
        const defaultMortgageLoanInterestOnlyLength = ref(props.initData.bankLoanTenureLength ?? 0)
        const formValid = ref(false)
        const displayErrors = ref(props.systemErrorMessage ? true : false)
        const isLoading = ref(false)
        const hideSystemErrorMessage = ref(false)

        const onChangeCallback = function (data) {
            housePrice.value = data.housePrice
            ownPayment.value = data.ownPayment
            housingType.value = data.housingType
            formValid.value = data.formValid
            defaultBankLoanTenureLength.value = data.defaultBankLoanTenureLength
            defaultMortgageLoanTenureLength.value = data.defaultMortgageLoanTenureLength
            defaultMortgageLoanInterestOnlyLength.value = data.defaultMortgageLoanInterestOnlyLength
        }

        const getProductType = () => {
            const houseType = props.housingTypes.find((x) => x.keyName === housingType.value)
            return houseType?.productType ?? ''
        }

        const submit = function () {
            displayErrors.value = true

            if (formValid.value) {
                const loadingTimeout = setTimeout(() => {
                    isLoading.value = true
                }, 100)
                displayErrors.value = false

                window.location.href = mapNewLoanUrlParams({
                    housePrice: housePrice.value,
                    ownPayment: ownPayment.value,
                    housingType: housingType.value,
                    mortgageLoanInterestOnlyLength: defaultMortgageLoanInterestOnlyLength.value,
                    mortgageLoanTenureLength: defaultMortgageLoanTenureLength.value,
                    bankLoanTenureLength: defaultBankLoanTenureLength.value,
                    productType: getProductType(),
                })
            }
        }

        const handleHideSystemErrorMessage = () => {
            hideSystemErrorMessage.value = true
        }

        return {
            displayErrors,
            onChangeCallback,
            submit,
            isLoading,
            handleHideSystemErrorMessage,
            hideSystemErrorMessage,
        }
    },
}