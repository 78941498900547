import { computed, ref, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { 
    getNavigationList,
    setNextItemInFocus,
    setPrevItemInFocus,
    navigateInMobileSubmenu
} from '../../../../Frontend/src/foundation/js/menu-key-helper'
import addScrollCallback from '../../../../Frontend/src/foundation/js/addScrollCallback'

export default {
    name: "MitNykreditMenu",
    props: {
        overlayId: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const store = useStore()
        const BurgermenuStateEnum = Object.freeze({
            PageStart: "",
            Open: "nyk-mit-nykredit-menu__top-menu__bottom__container__actions__burgermenu--open",
            Close: "nyk-mit-nykredit-menu__top-menu__bottom__container__actions__burgermenu--close",
        });

        const burgermenuState = ref(BurgermenuStateEnum.PageStart)
        const menu = ref(document.querySelector('.nyk-menu'))
        const menuClasses = ref("")
        const menuSticky = ref(false)
        const menuScrolling = ref(false)

        let menuIsInView = false
        let currentScrollTopValGeneral = window.scrollY

        let options = {
            root: document.querySelector("#scrollArea"),
            rootMargin: "0px",
            threshold: 0,
        };

        const menuBottomInViewCallback = (entries, observer) => {
            entries.forEach((entry) => {
                if (!entry.isIntersecting) {
                    menuScrolling.value = true
                }
            })
        }

        const menuTopInViewCallback = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    menuIsInView = entry.isIntersecting
                    menuScrolling.value = false
                    menuSticky.value = false

                } else {
                    menuIsInView = entry.isIntersecting
                }
            })
        }

        onMounted(() => {
            menu.value = document.querySelector('.nyk-mit-nykredit-menu')

            const menuBottom = menu.value.querySelector('.nyk-mit-nykredit-menu__top-menu__bottom')
            const menuTop = menu.value.querySelector('.nyk-mit-nykredit-menu__top-menu__top')
            let menuBottomObserver = new IntersectionObserver(menuBottomInViewCallback, options)
            let menuTopObserver = new IntersectionObserver(menuTopInViewCallback, options)
            menuBottomObserver.observe(menuBottom)
            menuTopObserver.observe(menuTop)

            addScrollCallback(() => {
                let scrollingDown = currentScrollTopValGeneral < window.scrollY
                let scrollingUp = currentScrollTopValGeneral > window.scrollY

                if (scrollingUp && !menuIsInView) {
                    menuScrolling.value = false
                    menuSticky.value = true
                }
                if (scrollingDown) {
                    menuSticky.value = false
                }

                // updating current scroll pos
                currentScrollTopValGeneral = window.scrollY
            })
        })

        const burgermenuUpdatedState = computed(() => {
            switch (burgermenuState.value) {
                case BurgermenuStateEnum.PageStart:
                    if (submenuOpen.value) {
                        burgermenuState.value = BurgermenuStateEnum.Open
                        return BurgermenuStateEnum.Open
                    } else {
                        burgermenuState.value = BurgermenuStateEnum.PageStart
                        return BurgermenuStateEnum.PageStart
                    }
                default:
                    if (submenuOpen.value) {
                        burgermenuState.value = BurgermenuStateEnum.Open
                        return BurgermenuStateEnum.Open
                    } else {
                        burgermenuState.value = BurgermenuStateEnum.Close
                        return BurgermenuStateEnum.Close
                    }
            }
        })

        const submenuOpen = computed(() => {
            const overlay = document.getElementById(props.overlayId)
            if (overlay) {
                overlay.classList.toggle('nyk-overlay--shown', store.state.menu.submenuOpen)
            }
            return store.state.menu.submenuOpen
        })

        const getMenuClasses = computed(() => {
            const submenuOpenClass = submenuOpen.value ? "nyk-mit-nykredit-menu--submenu-open" : ""
            const menuStickyClass = menuSticky.value ? "nyk-mit-nykredit-menu--sticky-top" : ""
            const menuScrollingClass = menuScrolling.value ? "nyk-mit-nykredit-menu--scrolling" : ""

            return menuClasses.value = `${submenuOpenClass} ${menuStickyClass} ${menuScrollingClass}`
        })

        watch(menuSticky, () => {
            document.querySelector('body').classList.toggle("nyk-menu-is-sticky", menuSticky.value)
        })

        watch(menuScrolling, () => {
            document.querySelector('body').classList.toggle("nyk-menu-is-scrolled", menuScrolling.value)
        })

        const toggleSubmenu = () => {
            store.dispatch("menu/searchOverlayShown", { open: false })
            store.dispatch('menu/submenuOpen', { open: !submenuOpen.value })
        }

        // Keyboard navigations
        const navigateForwardInMenu = (e) => {
            setNextItemInFocus(e.target, getNavigationList('nyk-js--menu-item'))
        }  

        const navigateBackInMenu = (e) => {
            setPrevItemInFocus(e.target, getNavigationList('nyk-js--menu-item'))
        }   
        
        const navigateToSubmenu = () => {
            if (submenuOpen.value) {
                closeSubmenu()
                return
            }

            toggleSubmenu()
            
            setTimeout(() => {
                const subNavigationList = getNavigationList('nyk-js--mobile-submenu-item')
                subNavigationList[0].focus()
                if (subNavigationList[0] !== document.activeElement) {
                    setNextItemInFocus(subNavigationList[0], subNavigationList)
                }
            }, 100)
        }

        const closeSubmenu = () => {
            toggleSubmenu()
            const navigationThree = getNavigationList('nyk-js--menu-item')
            navigationThree[navigationThree.length - 1].focus()
        }

        const navigateForwardInMobileSubmenu = (e) => {
            navigateInMobileSubmenu(e, true)
        }

        const navigateBackInMobileSubmenu = (e) => {
            navigateInMobileSubmenu(e, false)
        }

        return {
            burgermenuState,
            burgermenuUpdatedState,
            getMenuClasses,
            submenuOpen,
            toggleSubmenu,
            navigateForwardInMenu,
            navigateBackInMenu,
            navigateToSubmenu,
            navigateForwardInMobileSubmenu,
            navigateBackInMobileSubmenu,
            closeSubmenu
        }
    }
}