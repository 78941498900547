<script>
  import options from './CalculatorCompareTable.options'
  export default options
</script>

<template>
  <article ref="tableContainerRef" class="nyk-calculator-compare-table nyk-table">
    
      <template v-for="group, groupIndex in groupTables" :key="groupIndex">
        <div v-if="group.showTitle" class="nyk-calculator-compare-table__group" :class="markGroupsWithOnlyOneTable(group, 'nyk-calculator-compare-table__group')">
          <div v-for="title, titleIndex in group.titles">
            <h2 class="nyk-calculator-compare-table__group-title">{{ title }}</h2>
          </div>
        </div>

        <div class="nyk-calculator-compare-table__group" :class="markGroupsWithOnlyOneTable(group, 'nyk-calculator-compare-table__group')">
          <div v-for="table, tableIndex in group.tables" :key="tableIndex">
            <h2 v-if="group.showTitle" class="nyk-calculator-compare-table__group-title nyk-calculator-compare-table__group-title--show-mobile">{{ group.titles[tableIndex] }}</h2>
            <CalculatorTable 
              :headers="table.headers"
              :tooltip="table.tooltip"
              :collapse="table.collapse"
              :rows="table.rows"
              :labels="labels"
              :title="group.titles[tableIndex]"
            />
          </div>
        </div>
      </template>

  </article>
</template>
