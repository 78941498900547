<script>
import options from './CalculatorTable.options'
export default options
</script>

<template>
    <article v-if="!collapse" ref="tableContainerRef" class="nyk-calculator-table nyk-table" :class="tableStylesHiddenRow">
        <div class="nyk-table__container">
            <table class="nyk-table__container__table" :aria-label="title">
                <thead v-if="headers.length > 0" class="nyk-table__container__table__header">
                    <tr class="nyk-table__container__table__header__row">
                        <template v-for="(item, index) in headers" :key="index">
                            <th
                                class="nyk-table__container__table__header__row__item"
                                :class="`${item.styles ? 'nyk-table__container__table__header__row__item--' + item.styles : ''}`"
                                :scope="item.scope">
                                <div v-html="item.name"></div>
                                <button
                                    v-on:click="showTooltip(item.tooltipTitle)"
                                    v-if="tooltip"
                                    class="nyk-calculator-table__item-tooltip"
                                    aria-haspopup="true"
                                    :aria-label="labels.tooltipButtonLabel"
                                    tabindex="0">
                                    <span class="nyk-calculator-table__item-tooltip-symbol">
                                        <svg
                                            role="presentation"
                                            width="2"
                                            height="2"
                                            viewBox="0 0 2 2"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="1" cy="1" r="1" fill="#07094A" />
                                        </svg>
                                        <svg
                                            role="presentation"
                                            width="2"
                                            height="8"
                                            viewBox="0 0 2 8"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1V7" stroke="#07094A" stroke-width="2" stroke-linecap="square" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                        </template>
                    </tr>
                </thead>
                <tbody class="nyk-table__container__table__body" aria-live="polite">
                    <template v-for="(row, indexRow) in rows" :key="indexRow">
                        <tr
                            class="nyk-table__container__table__body__row"
                            :class="`${row.hide && !hiddensTableItemsShown ? 'nyk-table__container__table__body__row--hide' : ''}`"
                        >
                            <template v-for="(item, indexItem) in row.row" :key="indexItem">
                                <td class="nyk-table__container__table__body__row__item" :class="getTableItemStyles(item)">
                                    <div v-html="item.name"></div>
                                </td>
                            </template>
                        </tr>
                    </template>

                    <template v-if="hiddenTableItems">
                        <tr>
                            <td colspan="2">
                                <button
                                    v-on:click="toggleHiddenRows"
                                    class="nyk-button nyk-button--link nyk-calculator-table__load-more"
                                    :aria-expanded="hiddensTableItemsShown">
                                    <span>{{ hiddensTableItemsShown ? labels.showLessButton : labels.showMoreButton }}</span>
                                    <svg
                                        role="presentation"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M2.24005 13.4364L9.99893 5.67755L10.8828 6.56143L3.12393 14.3203L2.24005 13.4364Z"
                                            fill="#07094A" />
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M16.8739 14.3203L9.11505 6.56143L9.99893 5.67755L17.7578 13.4364L16.8739 14.3203Z"
                                            fill="#07094A" />
                                    </svg>
                                </button>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </article>

    <div v-else
        class="nyk-calculator-table nyk-table nyk-calculator-table--collapse" 
        :class="tableCollapsed ? 'nyk-calculator-table--hidden' : ''"
    >
        <button
            class="nyk-calculator-table__button"
            v-on:click="toggleTable"
            :aria-expanded="!tableCollapsed"
            :aria-controls="uniqueTableId"
            :id="`${uniqueTableId}-button`"
        >
            <div class="nyk-calculator-table__button-content">
                <span class="nyk-calculator-table__button-name">{{ getAccordionNameFromTable() }}</span> 
                <span class="nyk-calculator-table__item-collapse-icon">
                    <svg role="presentation" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.24005 13.4364L9.99893 5.67755L10.8828 6.56143L3.12393 14.3203L2.24005 13.4364Z" fill="#07094A" /><path fill-rule="evenodd" clip-rule="evenodd" d="M16.8739 14.3203L9.11505 6.56143L9.99893 5.67755L17.7578 13.4364L16.8739 14.3203Z" fill="#07094A" /></svg>
                </span>
            </div>
            <span class="nyk-calculator-table__button-value">{{ getAccordionValueFromTable() }}</span>
        </button>

        <div class="nyk-table__container" v-if="!tableCollapsed" :id="uniqueTableId" role="region" :aria-labelledby="`${uniqueTableId}-button`">
            <table class="nyk-table__container__table">
                <tbody class="nyk-table__container__table__body">
                    <template v-for="(row, indexRow) in rows" :key="indexRow">
                        <tr v-if="indexRow > 0"
                            class="nyk-table__container__table__body__row"
                            :class="`${row.hide && !hiddensTableItemsShown ? 'nyk-table__container__table__body__row--hide' : ''}`"
                        >
                            <template v-for="(item, indexItem) in row.row" :key="indexItem">
                                <td class="nyk-table__container__table__body__row__item" :class="getTableItemStyles(item)">
                                    <div v-html="item.name"></div>
                                </td>
                            </template>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>

</template>
