<script>
import options from './NewLoanCalculatorInputs.options'
export default options
</script>

<template>
    <article class="nyk-loan-calculator-inputs">
        <FormRadioGroupInput
            v-if="showHousingTypes"
            :label="labels.housingTypeTitle"
            v-model="housingType"
            inputName="housingTypes"
            :radioOptions="radioOptions"
            :helpMessage="labels.housingTypeHelpMessage"
            errorMessage=""
            v-on:change="setHousingTypes" />

        <FormGenericInput
            type="text"
            inputName="housePrice"
            v-model="housePrice"
            :modelValue="housePrice"
            :isRequired="true"
            :isValidationError="displayErrors && !housePriceValidation()"
            :isValid="housePriceValidation()"
            :label="labels.housePriceLabel"
            :helpMessageLabel="labels.housePriceHelpMessage"
            :errorMessageLabel="housePriceErrormMessage"
            inputmode="numeric"
            :resetAriaLabel="labels.inputResetAriaLabel"
            :prefix="labels.currencyPrefix"
            v-on:input="setHousePrice" />

        <FormGenericInput
            type="text"
            inputName="ownPayment"
            v-model="ownPayment"
            :modelValue="ownPayment"
            :label="labels.ownPaymentLabel"
            :isRequired="true"
            :isValidationError="displayErrors && !ownPaymentValidation()"
            :isValid="ownPaymentValidation()"
            :helpMessageLabel="labels.firstNameHelpText"
            :errorMessageLabel="dynamicOwnPaymentErrorMessage"
            :resetAriaLabel="labels.inputResetAriaLabel"
            :prefix="labels.currencyPrefix"
            inputmode="numeric"
            v-on:input="setOwnPayment" />
    </article>
</template>
