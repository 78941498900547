import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import Loader from "components/Loader/Loader";

export default {
  name: "ChatFeedbackReview",
  components: {
    Loader
  },
  props: {},
  setup() {
    const store = useStore();
    const isClosing = ref(false)
    function getLabel(label) {
      const returnLabel = store.state.chatPanel.labels[label];
      if (!returnLabel) console.error("Label does not exist", label);
      return returnLabel ? returnLabel : `[${label}]`;
    }

    function feedbackApiMessage() {
      return store.getters["chatPanel/getFeedbackApiMessage"];
    }

    const feedbackPayloadNegative =  computed(() => {
      return store.state.chatPanel.feedbackPayload === "negative";
    })

    async function closeFeedback() {
      isClosing.value = true
      try {
        await store.dispatch("chatPanel/resetChat");
      }
      finally {
        isClosing.value = false
      }
    }

    onMounted(() => {
      if (store.getters["chatPanel/chatInProgress"]) {
        store.dispatch("chatPanel/endConversation");
      }
    })

    return {
      feedbackPayloadNegative,
      isClosing,
      closeFeedback,
      feedbackApiMessage,
      getLabel,
    };
  },
};
