<script>
import options from "./ChatFeedbackInput.options"
export default options
</script>

<template>
    <div class="nyk-chat-feedback-input">
        <span class="nyk-chat-feedback-input__heading">{{ getLabel("reviewChatHeading") }}</span>
        <div class="nyk-chat-feedback-input__feedback-buttons">

            <button class="nyk-chat-feedback-input__button" :disabled="isSendingFeedback" @click="sendConversationFeedback({feedback: 'positive'})">
                <Loader v-if="isSendingFeedback === 'positive'" classNames="nyk-loader--inline nyk-loader--color-primary-dark" />
                <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 46 44">
                    <path fill="#07094A"
                        d="M5 19H3a3 3 0 0 0-3 3v18a3 3 0 0 0 3 3h2a3 3 0 0 0 3-3V22a3 3 0 0 0-3-3ZM46 28a4.926 4.926 0 0 0-1.6-3.652c.392-.72.599-1.528.6-2.348a5.006 5.006 0 0 0-5-5H23V7c0-5.537-3.925-7-6-7a1 1 0 0 0-.944.671L10 21v20.613A29.994 29.994 0 0 0 22 44h16a4 4 0 0 0 3.6-5.747 4.976 4.976 0 0 0 2.071-6.043A4.99 4.99 0 0 0 46 28Z" />
                </svg>
            </button>
            <button class="nyk-chat-feedback-input__button" :disabled="isSendingFeedback" @click="sendConversationFeedback({feedback: 'negative'})">
                <Loader v-if="isSendingFeedback === 'negative'" classNames="nyk-loader--inline nyk-loader--color-primary-dark" />
                <svg v-else viewBox="0 0 46 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M5 1.00098H3C1.34315 1.00098 0 2.34412 0 4.00098V22.001C0 23.6578 1.34315 25.001 3 25.001H5C6.65685 25.001 8 23.6578 8 22.001V4.00098C8 2.34412 6.65685 1.00098 5 1.00098Z"
                        fill="#07094A" />
                    <path
                        d="M46 16.0012C45.998 15.1601 45.7833 14.3332 45.376 13.5973C44.9686 12.8614 44.3818 12.2404 43.67 11.7922C44.0882 10.7019 44.11 9.49939 43.7316 8.39466C43.3532 7.28992 42.5988 6.35324 41.6 5.74823C41.865 5.20407 42.0018 4.60646 42 4.00123C42 2.94036 41.5786 1.92295 40.8284 1.1728C40.0783 0.422657 39.0609 0.00122937 38 0.00122937H22C17.8785 -0.0360867 13.7935 0.776484 10 2.38823V23.0012L16.056 43.3302C16.1243 43.5262 16.2518 43.6961 16.421 43.8163C16.5901 43.9365 16.7925 44.0012 17 44.0012C19.075 44.0012 23 42.5382 23 37.0012V27.0012H40C41.3256 26.9996 42.5964 26.4723 43.5338 25.535C44.4711 24.5977 44.9984 23.3268 45 22.0012C44.9981 21.1809 44.7919 20.3739 44.4 19.6532C44.9063 19.1895 45.3101 18.6251 45.5857 17.9962C45.8612 17.3673 46.0023 16.6878 46 16.0012Z"
                        fill="#07094A" />
                </svg>
            </button>
        </div>
    </div>
</template>