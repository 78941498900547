import {createApp} from 'vue'
import options from './ChatPanel.options'

const el = '.nyk-component--chat-panel'

export default function(Store){
    const DOM = document.querySelectorAll(el)
    DOM.forEach((ChatPanelDOM)=>{
        const labels = ChatPanelDOM.dataset.labels ? JSON.parse(ChatPanelDOM.dataset.labels) : null
        const feedbackActionId = ChatPanelDOM.dataset.feedbackActionId
        Store.commit('chatPanel/set_labels', labels)
        Store.commit('chatPanel/set_feedback_action_id', feedbackActionId)

        const template = ChatPanelDOM.innerHTML
        options.template = template
        const component = createApp(options).use(Store)
        component.mount(ChatPanelDOM)
    })
}