export default {
    namespaced: true,
    state: {
        modalTitle: '',
        modalSubTitle: '',
        componentName: '',
        componentProps: {},
        htmlContent: '',
        open: false,
        isLoading: false,
        style: '',
        hashObjects: [],
    },
    mutations: {
        set_modal_title(state, value) {
            state.modalTitle = value
        },
        set_modal_sub_title(state, value) {
            state.modalSubTitle = value
        },
        set_component_name(state, value) {
            state.componentName = value
        },
        set_component_props(state, value) {
            state.componentProps = value
        },
        set_html_content(state, value) {
            state.htmlContent = value
        },
        set_style(state, value) {
            state.style = value
        },
        set_open(state, value) {
            state.open = value
        },
        set_is_loading(state, value) {
            state.isLoading = value
        },
        set_hash_objects(state, value) {
            state.hashObjects = state.hashObjects.concat(value)
        },
    },
    actions: {
        setModalContent(context, payload) {
            if (payload.modalTitle) context.commit('set_modal_title', payload.modalTitle)
            if (payload.modalSubTitle) context.commit('set_modal_sub_title', payload.modalSubTitle)
            if (payload.componentName) context.commit('set_component_name', payload.componentName)
            if (payload.componentProps) context.commit('set_component_props', payload.componentProps)
            if (payload.htmlContent) context.commit('set_html_content', payload.htmlContent)
            if (payload.style) context.commit('set_style', payload.style)
        },
        setOpen(context, payload) {
            context.commit('set_open', payload.open)
            if (!payload.open) {
                context.commit('set_component_name', '')
                context.commit('set_component_props', {})
                context.commit('set_modal_title', '')
                context.commit('set_modal_sub_title', '')
                context.commit('set_html_content', '')
                context.commit('set_style', '')
                context.commit('set_is_loading', false)

                removeHashFromUrl()
            }
        },
        setIsLoading(context, payload) {
            context.commit('set_is_loading', payload.isLoading)
        },
        setHashObject(context, payload) {
            context.commit('set_hash_objects', payload.object)

            // start eventlistener for hash change
            reactOnHashChange(context)
        },
    },
}

const reactOnHashChange = (context) => {
    const hashChangeListener = function (e) {
        const hashObjects = context.state.hashObjects
        const hash = window.location.hash.replace('#', '')

        const hashObject = hashObjects.find((x) => x.anchorId === hash)

        if (hashObject) {
            context.commit('set_modal_title', hashObject.title)
            context.commit('set_html_content', hashObject.description)
            context.commit('set_style', ['small-long', 'white'])
            context.commit('set_open', true)
        }
    }

    window.addEventListener('hashchange', hashChangeListener, false)
}

const removeHashFromUrl = () => {
    const hash = window.location.hash
    if (hash) {
        // Get the current URL
        const currentURL = window.location.href
        // Remove the hash from the URL
        const urlWithoutHash = currentURL.split('#')[0]

        // Update the browser's address bar
        window.history.replaceState({}, document.title, urlWithoutHash)
    }
}