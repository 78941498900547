import { ref, computed } from 'vue'
import NewLoanCalculatorInputs from '../../../NewLoanCalculatorInputs/NewLoanCalculatorInputs.vue'
import { registerGtmDataLayer } from '../../../../../../../Video/gtmDataLayerService'

export default {
    name: 'AdjustLoanContent',
    components: {
        NewLoanCalculatorInputs,
    },
    props: {
        labels: {
            type: Object,
            required: true,
        },
        housingTypes: {
            type: Array,
            required: true,
        },
        initData: {
            type: Object,
            required: false,
        },
        index: {
            type: Number,
            required: true,
            default: 0,
        },
        onCallback: {
            type: Function,
            required: true,
            default: () => {},
        },
        showHousingTypes: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    setup(props) {
        const housePrice = ref('')
        const ownPayment = ref('')
        const housingType = ref(props.initData.housingType ?? '')
        const bankLoanTenureLength = ref(props.initData.bankLoanTenureLength ?? '')
        const mortgageLoanTenureLength = ref(props.initData.mortgageLoanTenureLength ?? '')
        const mortgageLoanInterestOnlyLength = ref(props.initData.mortgageLoanInterestOnlyLength ?? '')
        const formValid = ref(false)
        const displayErrors = ref(false)

        const loanType = computed(() => {
            const currentType = props.housingTypes.find((item) => item.keyName === housingType.value)
            const currentLoanType = currentType.loanTypes.find((item) => item.type === props.initData.loanType)
            if (!currentLoanType) {
                return currentType.loanTypes[0]
            }
            return currentLoanType
        })

        const onChangeCallback = function (data) {
            housePrice.value = data.housePrice
            ownPayment.value = data.ownPayment
            housingType.value = data.housingType
            formValid.value = data.formValid
        }

        const submit = function () {
            displayErrors.value = true

            if (formValid.value) {
                displayErrors.value = false

                // Google Tag Manager
                registerGtmDataLayer({
                    event: 'track-event',
                    eventAction: 'Hvad koster lån til ny bolig?',
                    eventCategory: 'beregnere',
                    eventLabel: 'submit',
                    eventNoninteraction: true,
                    eventValue: '0',
                    propertyDownpayment: ownPayment.value.toString(),
                    propertyPrice: housePrice.value.toString(),
                    propertyType: housingType.value,
                    userType: 'privat',
                })

                registerGtmDataLayer({
                    event: 'track-event.beregn_alle',
                    eventAction: 'alle',
                    eventCategory: 'beregn',
                    eventLabel: 'track-event.beregn_nytlaan beregn_nytlaan',
                    eventNoninteraction: true,
                    eventValue: '0',
                    event_source: '-240',
                })

                if (props.onCallback) {
                    props.onCallback(
                        {
                            loanType: loanType.value.type,
                            housePrice: housePrice.value,
                            ownPayment: ownPayment.value,
                            housingType: housingType.value,
                            mortgageLoanInterestOnlyLength: mortgageLoanInterestOnlyLength.value,
                            mortgageLoanTenureLength: mortgageLoanTenureLength.value,
                            bankLoanTenureLength: bankLoanTenureLength.value,
                        },
                        props.index
                    )
                }
            }
        }

        return {
            bankLoanTenureLength,
            mortgageLoanTenureLength,
            mortgageLoanInterestOnlyLength,
            loanType,
            displayErrors,
            onChangeCallback,
            submit,
        }
    },
}
